/* src/styles/main.css */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  

  .footer {
    text-align: center;
    padding: 1rem 0;
    background-color: #3498db;
    color: #fff;
  }
  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem; /* Add spacing between icons and text */
  }
  
  .social-links a {
    margin: 0 0.5rem; /* Adjust spacing between icons */
  }

  /* header */
  .header {
    text-align: center;
    background-color: #3498db;
    color: #fff;
    padding: 4rem 0;
  }
  
  .hero {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .hero h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
  
  .hero p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .cta-button {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    background-color: #2ecc71;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
  }

  .resume-link {
    display: inline-block;
    margin-top: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: #3498db;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .resume-link:hover {
    background-color: #2980b9;
  }



  .image-container {
    text-align: center;
    margin-top: 2rem; /* Increase the margin for better spacing */
  } 
  
  .profile-image {
    width: 150px;
    height: 130px;
    border-radius: 10%;
    border: 4px solid #3498db; /* Add a border with your accent color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
    transition: border-color 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, border-color 0.3s, box-shadow 0.3s;
    transition: transform 0.3s, border-color 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
  }
  
  .profile-image:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); /* Slight zoom on hover */
    border-color: #2980b9; /* Darker border color on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow on hover */
  }
  /*about .js*/
  .about-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;
    background-color: #f2f2f2;
  }
  
  .about-content {
    flex: 1 1;
    margin-right: 4rem;
  }
  
  .about-heading {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .about-description {
    font-size: 1.2rem;
    color: #555;
  }
  .interests-section {
    flex: 1 1;
    text-align: center;
  }
  
  .interests-heading {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .interests-list {
    display: flex;
    justify-content: space-around;
  }
  
  .interest-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .interest-icon {
    width: 80px;
    height: 80px;
    background-size: cover;
    margin-bottom: 1rem;
  }
  
.icon-list {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
  
  .icon {
    text-align: center;
    margin: 0 2rem;
    flex: 1 1;
    opacity: 0; /* Initially hidden */
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  }
  
  .icon.animated {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  
  .icon h3 {
    margin-top: 1rem;
    font-size: 1.3rem;
  }
  
    @media (max-width: 767px) {
        .about-section {
            display: block; /* Stack content on mobile */
            padding: 2rem; /* Adjust padding for better spacing on mobile */
          }
          
          .about-content {
            margin-right: 0; /* Reset margin on mobile */
          }
          
          .about-heading {
            font-size: 2rem; /* Decrease font size on mobile */
            margin-bottom: 1rem; /* Decrease margin for spacing */
          }
          
          .about-description {
            font-size: 1rem; /* Decrease font size on mobile */
            margin-bottom: 1.5rem; /* Decrease margin for spacing */
          }
          
          .interests-section {
            text-align: center; /* Center align on mobile */
            margin-top: 2rem; /* Adjust margin for spacing */
          }
          
          .interests-heading {
            font-size: 1.5rem; /* Decrease font size on mobile */
            margin-bottom: 1rem; /* Decrease margin for spacing */
          }
          
          .interests-list {
            flex-direction: column; /* Stack items vertically on mobile */
            align-items: center; /* Center align items on mobile */
          }
          
          .interest-item {
            margin-bottom: 1rem; /* Adjust margin for spacing */
          }
          
          .interest-icon {
            width: 60px; /* Decrease icon size on mobile */
            height: 60px; /* Decrease icon size on mobile */
            margin-bottom: 0.5rem; /* Decrease margin for spacing */
          }
          
          .icon-list {
            margin-top: 2rem; /* Adjust margin for spacing */
          }
          
          .icon {
            margin: 1rem 0; /* Adjust margin for spacing */
          }
      .footer {
        padding: 1rem 0; 
       /* Increase padding for better spacing on phones */
      }

      .header {
        padding: 2rem 0; /* Increase padding for better spacing on phones */
      }
    
      .hero h1 {
        font-size: 2.5rem; /* Decrease font size on phones */
        margin-bottom: 0.5rem; /* Decrease margin for spacing */
      }
    
      .hero p {
        font-size: 1.2rem; /* Decrease font size on phones */
        margin-bottom: 1rem; /* Decrease margin for spacing */
      }
    
      .cta-button {
        font-size: 1rem; /* Decrease font size on phones */
        padding: 0.3rem 0.8rem; /* Adjust padding for button on phones */
      }
      
      .resume-link {
        margin-top: 0.5rem; /* Decrease margin for spacing */
        padding: 0.3rem 0.8rem; /* Adjust padding for link on phones */
      }
  }
  
  /* skills */
.skill-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
  
  .skill-list li {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  /* jiggling.css */
.jiggle-effect {
    -webkit-animation: jiggle 1s infinite;
            animation: jiggle 1s infinite;
  }
  
  @-webkit-keyframes jiggle {
    0%, 100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }
    75% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }
  }
  
  @keyframes jiggle {
    0%, 100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    25% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
    }
    75% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }
  }
  .name-container {
    display: inline-block;
    cursor: pointer;
  }
  
  .name {
    display: inline-block;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }
  
  .name-container:hover .name {
    -webkit-animation: bounce 0.5s;
            animation: bounce 0.5s;
    -webkit-animation-iteration-count: 2;
            animation-iteration-count: 2;
  }
  
  @-webkit-keyframes bounce {
    0%, 100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }
/* project.css */
.project-section {
    padding: 5rem;
    background-color: #fff;
  }
  
  .project-heading {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .project-slider {
    display: flex;
    overflow-x: hidden;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
  }
  
  .project-item {
    flex: 0 0 100%;
    min-width: 100%;
    background-color: #f2f2f2;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    transition: opacity 0.5s;
    opacity: 0.5;
  }
  
  .project-item.active {
    flex: 0 0 70%;
    min-width: 70%;
    opacity: 1;
  }
  
  .project-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .project-item p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .project-item button {
    background-color: #3498db;
    border: none;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .project-item button:hover {
    background-color: #2980b9;
  }
  @media (max-width: 767px) {
    .project-section {
      padding: 2rem;
    }
  
    .project-heading {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .project-item {
      padding: 1.5rem;
      margin-bottom: 2rem;
      opacity: 1;
    }
  
    .project-item h3 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }
  
    .project-item p {
      font-size: 1rem;
    }
  
    .project-item button {
      padding: 0.3rem 0.8rem;
      font-size: 0.9rem;
    }
  }
/* awards.css */
.awards-section {
    padding: 5rem;
    background-color: #fff;
  }
  
  .awards-heading {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .awards-slider {
    display: flex;
    overflow-x: hidden;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
  }
  
  .award-item {
    flex: 0 0 100%;
    min-width: 100%;
    background-color: #f2f2f2;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    transition: opacity 0.5s;
    opacity: 0.5;
  }
  
  .award-item.active {
    flex: 0 0 70%;
    min-width: 70%;
    opacity: 1;
  }
  
  .award-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .award-item p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .award-item button {
    background-color: #3498db;
    border: none;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .award-item button:hover {
    background-color: #2980b9;
  }
  @media (max-width: 767px) {
    .awards-section {
      padding: 2rem;
    }
  
    .awards-heading {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .award-item {
      padding: 1.5rem;
      margin-bottom: 2rem;
      opacity: 1;
    }
  
    .award-item h3 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }
  
    .award-item p {
      font-size: 1rem;
    }
  
    .award-item button {
      padding: 0.3rem 0.8rem;
      font-size: 0.9rem;
    }
  }
/* experience.css */
.experience-section {
    padding: 5rem;
    background-color: #fff;
  }
  
  .experience-heading {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .experience-slider {
    display: flex;
    overflow-x: hidden;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
  }
  
  .experience-item {
    flex: 0 0 100%;
    min-width: 100%;
    background-color: #f2f2f2;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    transition: opacity 0.5s;
    opacity: 0.5;
  }
  
  .experience-item.active {
    flex: 0 0 70%;
    min-width: 70%;
    opacity: 1;
  }
  
  .experience-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .experience-item p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .experience-item button {
    background-color: #3498db;
    border: none;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .experience-item button:hover {
    background-color: #2980b9;
  }
  @media (max-width: 767px) {
    .experience-section {
      padding: 2rem;
    }
  
    .experience-heading {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .experience-item {
      padding: 1.5rem;
      margin-bottom: 2rem;
      opacity: 1;
    }
  
    .experience-item h3 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }
  
    .experience-item p {
      font-size: 1rem;
    }
  
    .experience-item button {
      padding: 0.3rem 0.8rem;
      font-size: 0.9rem;
    }
  }
/* skills.css */
.skills-section {
    padding: 5rem;
    background-color: #f2f2f2;
  }
  
  .skills-heading {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .skills-table {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  table {
    width: 80%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  tr {
    border-bottom: 1px solid #ccc;
  }
  
  .skill-item {
    padding: 1.5rem;
    font-size: 1.2rem;
    color: #333;
  }
  
  /* Media Query for Phones */
  @media (max-width: 767px) {
    .skills-section {
      padding: 2rem;
    }
  
    .skills-heading {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .skills-table {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    table {
      width: 90%; /* Adjust width for smaller screens */
    }
  
    .skill-item {
      padding: 1rem; /* Adjust padding */
      font-size: 1rem; /* Adjust font size */
    }
  }
  
/* college-experience.css */
.college-experience-section {
    padding: 5rem;
    background-color: #fff;
  }
  
  .college-experience-heading {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .college-experience-slider {
    display: flex;
    overflow-x: hidden;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
  }
  
  .college-experience-item {
    flex: 0 0 100%;
    min-width: 100%;
    background-color: #f2f2f2;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    transition: opacity 0.5s;
    opacity: 0.5;
  }
  
  .college-experience-item.active {
    flex: 0 0 70%;
    min-width: 70%;
    opacity: 1;
  }
  
  .college-experience-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .college-experience-item p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .college-experience-item button {
    background-color: #3498db;
    border: none;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .college-experience-item button:hover {
    background-color: #2980b9;
  }
  @media (max-width: 767px) {
    .college-experience-section {
      padding: 2rem;
    }
  
    .college-experience-heading {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .college-experience-item {
      padding: 1.5rem;
      margin-bottom: 2rem;
      opacity: 1;
    }
  
    .college-experience-item h3 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }
  
    .college-experience-item p {
      font-size: 1rem;
    }
  
    .college-experience-item button {
      padding: 0.3rem 0.8rem;
      font-size: 0.9rem;
    }
  }
